@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel-open:checked+.carousel-item {
    position: static;
    opacity: 100;
  }

  .carousel-item {
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
  }

  #carousel-1:checked~.control-1,
  #carousel-2:checked~.control-2,
  #carousel-3:checked~.control-3 {
    display: block;
  }

  .carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
  }

  #carousel-1:checked~.control-1~.carousel-indicators li:nth-child(1) .carousel-bullet,
  #carousel-2:checked~.control-2~.carousel-indicators li:nth-child(2) .carousel-bullet,
  #carousel-3:checked~.control-3~.carousel-indicators li:nth-child(3) .carousel-bullet {
    color: #2b6cb0;
  }